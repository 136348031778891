"use client";

import { Breadcrumb, Button, Col, Divider, Tag, Typography } from "antd";
import MainLayout from "../Layout/MainLayout";
import { slugify } from "../Utils/HelperFunctions";
import blogContent from "../Data/blogContent.json";
import { useNavigate, useParams } from "react-router-dom";
import { colorPalette } from "../Config/Theme";
import { blog_path } from "../Config/Paths";

const { Title, Paragraph } = Typography;

const BlogPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const blog = blogContent.find(
    (blog: any) => slugify(blog.title) === params.slug
  );

  if (!blog) {
    return <Paragraph>Blog not found</Paragraph>;
  }

  return (
    <MainLayout>
      <Col
        className="blog-page blog-container"
        style={{ marginTop: "0px", paddingBottom: "20px" }}
      >
        <Breadcrumb style={{ paddingTop: "20px" }}>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href={blog_path}>Blogs</Breadcrumb.Item>
          <Breadcrumb.Item>{blog.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="text-left section"
          style={{ marginTop: "0px", paddingBottom: "20px" }}
        >
          <Title
            level={2}
            style={{ marginTop: "0px", paddingTop: "20px" }}
            className="text-black"
          >
            {blog.title}
          </Title>
          <Paragraph>{blog.date}</Paragraph>
          <Divider />

          {blog.img && (
            <img
              src={blog.img}
              alt={blog.title}
              className="img-fluid mb-5"
              style={{ width: "100%" }}
            />
          )}

          {/* <Paragraph>{blog.summary}</Paragraph> */}

          {blog.sections.map((section: any, index: number) => (
            <div key={index} className="blog-section">
              {section.heading && (
                <Title level={3} className="sub-heading">
                  {section.heading}
                </Title>
              )}

              {section.entries.map((entry: any, entryIndex: number) => (
                <div key={entryIndex} className="entry-section">
                  {entry.title && (
                    <Title level={4} className="entry-heading">
                      {entry.title}
                    </Title>
                  )}

                  {entry.points.map((point: string, pointIndex: number) => (
                    <Paragraph key={pointIndex}>{point}</Paragraph>
                  ))}
                </div>
              ))}
            </div>
          ))}

          {blog.boilerPlate.flag && (
            <div className="boilerplate-section">
              <Title level={3} className="boilerplate-title">
                {blog.boilerPlate.title}
              </Title>
              {blog.boilerPlate.points.map((point: string, index: number) => (
                <Paragraph key={index} className="boilerplate-point">
                  {point}
                </Paragraph>
              ))}
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </Button>
            </div>
          )}

          {/* Boilerplate content */}

          {blog.footer.flag && (
            <div>
              <Title level={4} className="blog-footer-title">
                {blog?.footer?.title}
              </Title>

              {blog?.footer?.points.map((point: string, index: number) => (
                <Tag
                  key={index}
                  color={colorPalette[2]}
                  style={{
                    fontSize: "16px",
                    padding: "5px 10px",
                    marginTop: "8px",
                  }}
                >
                  {point}
                </Tag>
              ))}
            </div>
          )}

          {/* <div className="mt-5">
            {blog.tags.map((tag: string, index: number) => (
              <Tag
                key={index}
                color="#cc6699"
                style={{
                  fontSize: "16px",
                  padding: "5px 10px",
                  marginTop: "8px",
                }}
              >
                {tag}
              </Tag>
            ))}
          </div> */}
        </div>
      </Col>
    </MainLayout>
  );
};

export default BlogPage;
