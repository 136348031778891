import "./App.css";
import AppRoute from "./Router/AppRoute";
import { ConfigProvider } from "antd";
import { theme, globalStyles } from "./Config/Theme";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  ${globalStyles}
`;

function App() {
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <GlobalStyle />
        <AppRoute />
      </ConfigProvider>
    </div>
  );
}

export default App;
