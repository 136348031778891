"use client";
import { Card, Typography, Tag, Space, Row } from "antd";

import { useNavigate } from "react-router-dom";
import { blog_path } from "../Config/Paths";
import { slugify, truncateText } from "../Utils/HelperFunctions";

const { Title, Paragraph } = Typography;

const BlogCard = ({ blog }: { blog: any }) => {
  const navigate = useNavigate();

  return (
    <Card
      bordered={false}
      hoverable
      className="blog-card"
      cover={<img alt="example" src={blog?.thumbnail} height={200} />}
      onClick={() => navigate(`${blog_path}/${slugify(blog.title)}`)}
    >
      <Title
        level={4}
        className="text-black"
        onClick={() => navigate(`${blog_path}/${slugify(blog.title)}`)}
        style={{ cursor: "pointer", marginBottom: "16px" }}
      >
        {blog.title}
      </Title>

      <Paragraph>{truncateText(blog.summary, 150)}</Paragraph>

      <Row justify={"start"}>
        <Space>{blog?.date && <Tag>{blog.date}</Tag>}</Space>
      </Row>
    </Card>
  );
};

export default BlogCard;
