import { Col } from "antd";
import { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";

const VideoComponent = ({ video }: { video: string }) => {
  const videoRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Col className="text-center">
      <div className="video-container">
        <div>
          <video
            className="problem-video"
            ref={videoRef}
            controls={isPlaying} // Disable native controls for custom behavior
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {isPlaying ? (
          <button
            className={`pause-btn ${
              isHovered ? " pause-btn-show" : "pause-btn-hide"
            } `}
            onClick={handlePlayPause}
          >
            <FaPause />
          </button>
        ) : (
          <button className="play-pause-btn" onClick={handlePlayPause}>
            <FaPlay />
          </button>
        )}
      </div>
    </Col>
  );
};

export default VideoComponent;
