import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/Home";
import {
  ai_sales_coach_path,
  blog_path,
  contact_path,
  home_path,
  rms_path,
} from "../Config/Paths";

import ContactPage from "../Pages/Contact";
import RMSPage from "../Pages/RMS";
import BlogsHome from "../Pages/BlogsHome";
import BlogPage from "../Pages/Blog";
import AISalesCoachPage from "../Pages/AISalesCoach";
import ScrollToTop from "../Components/ScrollTotop";
// import AISalesCoachPage from "../Pages/AISalesCoach";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path={home_path}
          element={
            <>
              <HomePage />
            </>
          }
        />
        <Route
          path={rms_path}
          element={
            <>
              <RMSPage />
            </>
          }
        />

        <Route
          path={contact_path}
          element={
            <>
              <ContactPage />
            </>
          }
        />

        <Route
          path={blog_path}
          element={
            <>
              <BlogsHome />
            </>
          }
        />
        <Route
          path={blog_path + "/:slug"}
          element={
            <>
              <BlogPage />
            </>
          }
        />
        <Route
          path={ai_sales_coach_path}
          element={
            <>
              <AISalesCoachPage />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
