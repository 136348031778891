import { Col, Row, Card, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FaBullhorn,
  FaChartBar,
  FaBrain,
  FaPaperclip,
  FaPaperPlane,
  FaClipboard,
} from "react-icons/fa";
import VideoComponent from "../Components/VideoComponent"; // Assuming you have a VideoComponent
import AICoachVideo from "../Assets/Videos/AI Coach.mp4";
import MainLayout from "../Layout/MainLayout";
const { Title, Paragraph } = Typography;

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <Col className="container hero">
      <Row justify="start" align="middle" gutter={[0, 16]}>
        <Col xs={24} md={12}>
          <Title level={1} className="text-white">
            AI Sales Coach
          </Title>
          <Title level={5} className="text-white">
            Boost your sales team's potential with AI-driven virtual training
            for continuous improvement in sales skills.
          </Title>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => navigate("/contact")} // Replace with your actual contact path
          >
            Contact Us
          </Button>
        </Col>
        <Col xs={24} md={12}>
          <VideoComponent video={AICoachVideo} />{" "}
          {/* Replace with actual video */}
        </Col>
      </Row>
    </Col>
  );
};

const FeaturesSection = () => {
  const features = [
    {
      icon: <FaBullhorn className="icon" />,
      title: "Realistic Sales Simulations",
      description:
        "Simulate real-world sales scenarios to enhance pitching and objection-handling skills.",
    },
    {
      icon: <FaClipboard className="icon" />,
      title: "Instant \nFeedback",
      description:
        "Receive immediate feedback on your performance to help identify areas for improvement.",
    },
    {
      icon: <FaChartBar className="icon" />,
      title: "Personalized \nInsights",
      description:
        "Get actionable, personalized insights based on your performance to guide your growth.",
    },
    {
      icon: <FaBrain className="icon" />,
      title: "Unlimited Practice to Track Progress",
      description:
        "Access unlimited practice sessions and track your performance over time for continuous improvement.",
    },
  ];

  return (
    <div className="features-section container section">
      <div className="section-title text-center">
        <Title level={2}>Features</Title>
        <Paragraph>
          Discover the key features of AI Sales Coach that will revolutionize
          your sales training.
        </Paragraph>
      </div>
      <Row justify="center" gutter={[16, 16]} className="card-container">
        {features.map((feature, index) => (
          <Col xs={24} sm={12} md={6} key={index} className="card-column">
            <Card bordered={false} className="custom-card">
              <div className="icon-container bg-light">{feature.icon}</div>
              <Title level={3} className="title-wrap">
                {feature.title}
              </Title>
              <Paragraph className="card-description">
                {feature.description}
              </Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const WhyChooseUsSection = () => {
  return (
    <div className="why-choose-us-section container section">
      <div className="section-title text-center">
        <Title level={2}>Why Choose AI Sales Coach?</Title>
        <Paragraph>
          Empower your sales team to excel in client interactions, boost sales,
          and improve customer satisfaction
        </Paragraph>
      </div>
      <Row justify="center" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card bordered={true} className="why-choose-us-card">
            <Paragraph className="why-choose-us-description">
              With AI Sales Coach, your team will receive tailored training that
              improves real-world sales interactions. The AI-driven platform
              enhances product pitching, objection handling, and overall client
              communication.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={12} className="mt-4">
          <Card bordered={true} className="why-choose-us-card">
            <Paragraph className="why-choose-us-description">
              The platform also allows sales teams to track progress and refine
              their strategies through consistent practice, ensuring continuous
              growth and improvement.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const AISalesCoachPage = () => {
  return (
    <MainLayout>
      <div className="ai-sales-coach-page">
        <HeroSection />
        <FeaturesSection />
        <WhyChooseUsSection />
      </div>
    </MainLayout>
  );
};

export default AISalesCoachPage;
