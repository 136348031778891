import { useEffect, useState } from "react";

export function slugify(text: string = "") {
  return text
    ?.toString()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export const useWindowWidth = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 913);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};





export const unSlugify = (text : string) => {
  const decodedName = text?.replace(/-/g, " ");
  return decodedName.charAt(0).toUpperCase() + decodedName.slice(1);
};




export const truncateText = (text : string, length : number) => {
  return text.length > length ? text.substring(0, length) + '...' : text;
};

