import BlogCard from "./BlogCard";

const BlogList = ({ blogs, limit }: { blogs: any; limit: number }) => {
  return (
    <div className="blogs-grid">
      {blogs.slice(0, limit).map((blog: any, index: number) => (
        <BlogCard key={index} blog={blog} />
      ))}
    </div>
  );
};

export default BlogList;
