import { Col, Divider, Typography } from "antd";
import MainLayout from "../Layout/MainLayout";
import BlogList from "../Components/BlogList";
import blogContent from "../Data/blogContent.json";

const { Title } = Typography;

const BlogsHome = () => {
  return (
    <MainLayout>
      <Col
        className="container"
        style={{ marginTop: "0px", paddingBottom: "20px" }}
      >
        <Col
          className="section"
          style={{ marginTop: "0px", paddingBottom: "20px" }}
        >
          <div>
            <Title level={2} className="text-center section-title">
              BLOGS
            </Title>
          </div>
          <Divider />
          <section style={{ paddingTop: "24px" }}>
            <BlogList blogs={blogContent} limit={100} />
          </section>
        </Col>
      </Col>
    </MainLayout>
  );
};

export default BlogsHome;
