

export const colorPalette = [ "#0c3cfa", "#ffe436","#ed662d"];

export const theme = {
  token: {
    colorPrimary: colorPalette[0],
    colorTextHeading: colorPalette[0],
    fontSizeHeading1: 55,
    fontSizeHeading2: 40,
    fontSizeHeading3: 25,
    colorLink: "black",
    fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont,
               "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
             "Helvetica Neue", sans-serif`,
    borderRadius: 8,
    padding: 16,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadiusLG: 0,
  },
  components: {
    Typography: {
      // Increase font size for Paragraph
      fontSize: 16,
    },
    Input: {
      borderRadius: 0,
      controlHeight: 40,
    },
    Select: {
      borderRadius: 0,
      controlHeight: 40,
      colorText: "black",
    },
    Button: {
      textTextColor: "black", 
      primaryColor: "white",
     
    },
    Card : {
      borderRadius: 0
    }
  },

  cssVar: true,
  hashed: false,
};

export const globalStyles = `
:root {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #c92b2e;
  --bs-pink: #e83e8c;
  --bs-red: #ff3e1d;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffab00;
  --bs-green: #71dd37;
  --bs-teal: #20c997;
  --bs-cyan: #03c3ec;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: rgba(67, 89, 113, 0.6);
  --bs-gray-dark: #252525;
  --bs-gray-25: rgba(67, 89, 113, 0.025);
  --bs-gray-50: rgba(67, 89, 113, 0.05);
  --bs-primary: ${colorPalette[0]};
  --bs-primary-2: ${colorPalette[1]};
  --bs-primary-3:${colorPalette[2]};
  --bs-secondary: #8592a3;
  --bs-success: #71dd37;
  --bs-info: #03c3ec;
  --bs-warning: #ffab00;
  --bs-danger: #ff3e1d;
  --bs-light: #fcfdfd;
  --bs-dark: #233446;
  --bs-border-color : #ddd;
  --bs-gray: rgba(67, 89, 113, 0.1);
  --bs-primary-rgb: 105, 108, 255;
  --bs-secondary-rgb: 133, 146, 163;
  --bs-success-rgb: 113, 221, 55;
  --bs-info-rgb: 3, 195, 236;
  --bs-warning-rgb: 255, 171, 0;
  --bs-danger-rgb: 255, 62, 29;
  --bs-light-rgb: 252, 253, 253;
  --bs-dark-rgb: 35, 52, 70;
  --bs-gray-rgb: 67, 89, 113;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 67, 89, 113;
  --bs-body-color-rgb: 105, 122, 141;
  --bs-body-bg-rgb: 245, 245, 249;
  --bs-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  --bs-font-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-root-font-size: 16px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.53;
  --bs-body-color: #697a8d;
  --bs-body-bg: #f5f5f9;
  --bs-body-text-align: "left";
}

`
