import { useRef, useState } from "react";
import { contact_path } from "../Config/Paths";
import MainLayout from "../Layout/MainLayout";
import { useMediaQuery } from "react-responsive";
import { Typography, Button, Card, Row, Col, Carousel } from "antd";
import {
  FaClock,
  FaChartLine,
  FaQuestionCircle,
  FaInfoCircle,
  FaRedoAlt,
  FaEdit,
  FaLayerGroup,
  FaMoneyBillWave,
  FaPuzzlePiece,
  FaSitemap,
  FaFileAlt,
  FaSyncAlt,
  FaRobot,
  FaListAlt,
  FaArrowLeft,
  FaArrowRight,
  FaCog,
  FaDollarSign,
  FaUserFriends,
  FaClipboardCheck,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import VideoComponent from "../Components/VideoComponent";
import demoVideo from "../Assets/Videos/quick-demo.mp4";

const { Title, Paragraph } = Typography;

const RMSPage = () => {
  return (
    <MainLayout>
      <div className="pb-0 px-0">
        <section id="intro" className="intro-section ">
          <HeroSection />
          <ChallengesSection />
          <SolutionOverview />
          <JobDescriptionFeatures />
          <WhyUseThisTool />
          <OptionalAddOns />

          {/* <Col className="container">
            <Title level={3}>Flexible Engagement Models for Your Needs</Title>
            <Paragraph>
              This tool can be used either as an online version or an excel
              version – the excel version does not have AI capability and will
              be relying upon pre-set “rules” to determine salary. Either way,
              in the first year, there will be setup required – an
              organisation’s salary ranges, input salary data, sample PDs, PD
              templates, any competency framework and other relevant inputs
              would need to be setup in the tool. As such, the first year would
              include setup costs and tool cost but from the second year, there
              would only be the tool cost, unless there is a need to change any
              framework. Salary range update every year will not be any
              additional costs.
            </Paragraph>
            <Paragraph>
              This tool can also be integrated (at an additional cost) with your
              HRIS system, depending on complexity of your system and APIs
              available.
            </Paragraph>
          </Col> */}
        </section>
      </div>
    </MainLayout>
  );
};

export default RMSPage;

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <Col className="container hero ">
      <Row justify="start" align="middle" gutter={[0, 16]}>
        <Col xs={24} md={12}>
          <Title level={1} className="text-white">
            AI-Assisted Role Management System{" "}
          </Title>
          <Title level={5} className="text-white">
            Simplifying end to end role definition, creation and management
          </Title>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => navigate(contact_path)}
          >
            Contact Us
          </Button>
        </Col>
        <Col xs={24} md={12}>
          <VideoComponent video={demoVideo} />
        </Col>
      </Row>
    </Col>
  );
};

const ChallengesSection = () => {
  const keyProblems = [
    {
      icon: <FaClock className="icon" />,
      title: "Time Consuming",
      description:
        "Significant time is spent drafting detailed and precise PDs in collaboration with line managers.",
    },
    {
      icon: <FaChartLine className="icon" />,
      title: "Role Slotting and Benchmarking",
      description:
        "Internal teams must either slot roles to levels or benchmark them using job evaluations, adding complexity to the process.",
    },
    {
      icon: <FaQuestionCircle className="icon" />,
      title: "Role Requirements",
      description:
        "The recruiting team struggles to fully grasp the role, making it difficult to formulate appropriate questions for candidates.",
    },
    {
      icon: <FaInfoCircle className="icon" />,
      title: "Loss of Information",
      description:
        "Important information and intent may be lost during the drafting and evaluation phases, resulting in inconsistent outcomes.",
    },
    {
      icon: <FaRedoAlt className="icon" />,
      title: "Numerous Iterations",
      description:
        "Crafting thorough and accurate PDs demands careful alignment of responsibilities, skills, and expectations, leading to multiple iterations that can delay recruitment efforts.",
    },
    {
      icon: <FaFileAlt className="icon" />,
      title: "Inconsistent Documentation",
      description:
        "PDs often lack standardization across the organization, leading to varied interpretations and further inconsistencies.",
    },
  ];

  return (
    <div className="challenges-section container ">
      <div className="section-title text-center">
        <Title level={2}>CHALLENGES IN PD</Title>
        <Paragraph>
          Recruiters and HR struggle with drafting PDs, causing inefficiency and
          inconsistency.
        </Paragraph>
      </div>
      <Row justify="center" gutter={[16, 16]} className="card-container">
        {keyProblems.map((problem, index) => (
          <Col xs={24} sm={12} md={8} key={index} className="card-column">
            <Card bordered={false} className="custom-card">
              <div className="icon-container bg-light">{problem.icon}</div>
              <Title level={3} className="">
                {problem.title}
              </Title>
              <Paragraph className="card-description ">
                {problem.description}
              </Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const SolutionOverview = () => {
  const solutionDetails = [
    {
      icon: <FaEdit className="icon" />,
      title: "Customisable Pre-Created PDs",
      description:
        "Offers access to customisable pre-created PDs, either from our database or using your “golden” PDs.",
    },
    {
      icon: <FaChartLine className="icon" />,
      title: "Role-Based Suggestions",
      description:
        "Provides role-based suggestions and real-time editing capabilities using your organisation’s template.",
    },
    {
      icon: <FaLayerGroup className="icon" />,
      title: "Level Slotting",
      description:
        "Allows selection of role slotting based on pre-defined levels using level descriptor mapping.",
    },
    {
      icon: <FaMoneyBillWave className="icon" />,
      title: "Job Evaluation",
      description:
        "Supports job evaluation using your internal job evaluation framework to determine appropriate levels and salary ranges.",
    },
    {
      icon: <FaPuzzlePiece className="icon" />,
      title: "Positioning within Salary Range",
      description:
        "Lets you pre-set positioning within a salary range based on function, team, business unit, criticality, harder-to-source roles, specialised skills, or other factors within your remuneration framework.",
    },
    {
      icon: <FaSitemap className="icon" />,
      title: "Competency Mapping",
      description:
        "Maps to either your internal competency framework or uses our suggested framework to determine appropriate recruitment questions.",
    },
    // {
    //   icon: <FaClock className="icon" />,
    //   title: "Fast and Efficient",
    //   description:
    //     "The entire process, as an online system, takes less than 5 minutes, significantly reducing time and inconsistency issues.",
    // },
  ];

  return (
    <Col id="solution-overview" className="section  section-bg ">
      <Col className="container">
        <div className="section-title text-center">
          <Title level={2} className="text-white">
            {" "}
            SOLUTION OVERVIEW{" "}
          </Title>
          <Paragraph className="text-white">
            Our AI-powered tool streamlines the creation process by offering a
            range of customisable features designed to enhance the efficiency
            and accuracy of position descriptions creation.
          </Paragraph>
        </div>
        <Row gutter={[24, 24]} justify={"center"}>
          {solutionDetails.map((solution, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              <Card
                bordered={false}
                size="small"
                className="text-left bg-dark "
              >
                <Row justify="start" align="middle">
                  <div className="icon-container bg-white">{solution.icon}</div>
                </Row>

                <Title level={3} className="text-primary">
                  {solution.title}
                </Title>
                <Paragraph className="card-description text-white">
                  {solution.description}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Col>
  );
};
// const JobDescriptionFeatures = () => {
//   const features = [
//     "              The AI engine instantly suggests essential skills, responsibilities, and position requirements.",
//     "  Adjust PDs as needed to align with evolving company or role-specific needs.",
//     "Attracts the right talent with well-defined roles and expectations.",
//     "    Get salary range suggestions based on your internal job evaluation framework.",
//     "       Receive tailored recruitment questions to ensure comprehensive candidate assessment.",
//   ];

//   return (
//     <div className="job-description-features container">
//       <div className="section-title text-center">
//         <Title level={2}>KEY BENEFITS</Title>
//         <Paragraph>
//           Discover how our solution enhances efficiency, consistency, and
//           accuracy in the PD creation process
//         </Paragraph>
//       </div>
//       <Row gutter={[24, 24]} align="middle" justify="center">
//         {/* Image Section */}
//         {/* <Col xs={24} lg={14} className="text-center">
//           <img
//             src="https://res.cloudinary.com/dbgw9jgum/image/upload/v1729525134/Screenshot_2024-10-21_at_9.01.28_PM_eepb3o.png"
//             alt="Job Description Insights"
//             width={"100%"}
//             className="border"
//           />
//         </Col> */}

//         {/* Text and List Section */}
//         <Col xs={24} lg={10} style={{ fontSize: "18px" }}>
//           {features.map((item, index) => {
//             return (
//               <Paragraph className="list-container">
//                 <span className="text-white list-number-2 ">{index + 1}</span>
//                 {item}
//               </Paragraph>
//             );
//           })}
//         </Col>
//       </Row>
//     </div>
//   );
// };

// const OptionalAddOns = () => {
//   const addOns = [
//     "Competency Assessment: The tool can define and automate the inclusion of technical and behavioural competencies, ensuring that the PD aligns with both skill requirements and organisational culture.",
//     "Recruitment Question Suggestions: This feature offers suggested recruitment questions tailored to the position description, ensuring comprehensive assessment of candidates. These questions are generated using AI and consist of a combination of behavioural and technical questions.",
//   ];

//   return (
//     <Col className="optional-addons  section-bg">
//       {/* <Title level={3}>Optional Add-Ons</Title> */}
//       {/* <Paragraph>
//         To get the most from the role management system, clients can opt for the
//         following additional features:
//       </Paragraph> */}

//       <Col className="job-description-features container">
//         <div className="section-title text-center">
//           <Title level={2}>OPTIONAL ADD ONS</Title>
//           <Paragraph className="text-white">
//             Enhance your experience with powerful additional features
//           </Paragraph>
//         </div>
//         <Row gutter={[24, 24]} align="middle" justify="center">
//           {/* Image Section */}

//           {/* Text and List Section */}

//           <Col xs={24} lg={12} className="text-center">
//             <img
//               src="https://res.cloudinary.com/dbgw9jgum/image/upload/v1722974817/Screenshot_2024-08-07_at_1.33.12_AM_ypjevm.png"
//               alt="Job Description Insights"
//               width="60%"
//               className="border"
//             />
//           </Col>
//           <Col xs={24} lg={12} style={{ fontSize: "18px" }}>
//             {addOns.map((item, index) => {
//               return (
//                 <Col>
//                   <Paragraph className="list-container text-white">
//                     {item}
//                   </Paragraph>
//                 </Col>
//               );
//             })}
//           </Col>
//         </Row>
//       </Col>
//     </Col>
//   );
// };

const WhyUseThisTool = () => {
  const reasons = [
    {
      icon: <FaClock className="icon" />,
      title: "Efficiency",
      text: "Significantly reduce the time spent on creating position descriptions, transforming hours of effort into mere minutes, enhancing productivity.",
    },
    {
      icon: <FaListAlt className="icon" />,
      title: "Comprehensive Solution",
      text: "Delivers not only position descriptions but also provides salary estimation, along with optional add-ons for additional features tailored to your needs.",
    },
    {
      icon: <FaRobot className="icon" />,
      title: "AI-Powered Suggestions",
      text: "Receive intelligent, automatic suggestions for key responsibilities and salary ranges, ensuring alignment with industry standards and expectations.",
    },
    {
      icon: <FaSyncAlt className="icon" />,
      title: "Continuous Improvement",
      text: "Facilitates the evaluation of position descriptions, allowing for the gathering of feedback to ensure relevance, accuracy, and overall effectiveness over time.",
    },
  ];
  return (
    <Col id="why-this-tool" className="section section-bg ">
      <Col className="container">
        <div className="section-title text-center">
          <Title level={2} className="text-white">
            {" "}
            WHY USE THIS TOOL
          </Title>
          <Paragraph className="text-white">
            Streamline PD creation with AI-powered insights, saving time and
            ensuring accuracy.
          </Paragraph>
        </div>

        <Row gutter={[24, 24]} justify={"center"}>
          {reasons.map((solution, index) => (
            <Col xs={24} sm={12} md={12} key={index}>
              <Card bordered={false} size="small" className="text-left  ">
                <Row justify="start" align="middle">
                  <div className="icon-container bg-light ">
                    {solution.icon}
                  </div>
                </Row>
                <Title level={3}>{solution.title}</Title>
                <Paragraph className=" text-left card-description">
                  {solution.text}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Col>
  );
};

const JobDescriptionFeatures = () => {
  const carouselRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [currentSlide, setCurrentSlide] = useState(0);

  const keyBenefits = [
    {
      title: "AI-Driven PD Creation",
      text: "The AI engine quickly suggests necessary skills, responsibilities, and position requirements to streamline the drafting process.",
      icon: <FaRobot className="icon" />,
      bgClass: "bg-primary",
    },
    {
      title: "Customizable Database",
      text: "Easily modify PDs to meet the evolving needs of your organization or specific roles as they change over time.",
      icon: <FaCog className="icon" />,
      bgClass: "bg-primary-2",
    },
    {
      title: "Fast, Automated Filling",
      text: "Get instant salary range suggestions tailored to your internal job evaluation framework to save time.",
      icon: <FaDollarSign className="icon" />,
      bgClass: "bg-primary-3",
    },
    {
      title: "Real-Time Editing",
      text: "Receive tailored recruitment questions in real-time, ensuring comprehensive assessments of candidates during hiring.",
      icon: <FaEdit className="icon" />,
      bgClass: "bg-primary",
    },
    {
      title: "Salary Range Guidance",
      text: "After creating the PD, the AI tool provides salary range recommendations based on your internal structures and market trends.",
      icon: <FaMoneyBillWave className="icon" />,
      bgClass: "bg-primary",
    },
    {
      title: "Enhanced Compliance",
      text: "Ensure that all PDs meet legal and organizational compliance standards, minimizing risks and legal issues.",
      icon: <FaClipboardCheck className="icon" />, // New icon
      bgClass: "bg-primary-2",
    },
    {
      title: "User-Friendly Interface",
      text: "The intuitive design of the tool allows users of all skill levels to create and modify PDs with ease.",
      icon: <FaUserFriends className="icon" />, // New icon
      bgClass: "bg-primary-3",
    },
  ];

  const slidesToShow = isMobile ? 1 : 3.2;

  // Update the current slide index on slide change
  const handleSlideChange = (current: number) => {
    setCurrentSlide(current);
  };

  // Navigate to previous and next slides with controls
  const handlePrev = () => {
    // @ts-ignore
    carouselRef.current?.prev();
  };

  const handleNext = () => {
    // @ts-ignore
    carouselRef.current?.next();
  };

  return (
    <Col className="container  section">
      <div className="section-title text-center">
        <Title level={2}>KEY BENEFITS</Title>
        <Paragraph>
          Discover how our solution enhances efficiency, consistency, and
          accuracy in the PD creation process
        </Paragraph>
      </div>
      <div className="carousel-container">
        <Button
          shape="round"
          className="carousel-arrow left-arrow"
          type="primary"
          icon={<FaArrowLeft />}
          onClick={handlePrev}
          disabled={currentSlide === 0} // Disable if at the start
        />
        <Carousel
          ref={carouselRef}
          dots={false}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          infinite={false}
          afterChange={handleSlideChange} // Update on slide change
        >
          {keyBenefits.map((activity, index) => (
            <div key={index} className="carousel-card">
              <Card>
                <div className={`icon-container bg-light`}>{activity.icon}</div>
                <Title level={3}>{activity.title}</Title>
                <Paragraph className="card-description">
                  {activity.text}
                </Paragraph>
              </Card>
            </div>
          ))}
        </Carousel>
        <Button
          shape="round"
          className="carousel-arrow right-arrow"
          type="primary"
          icon={<FaArrowRight />}
          onClick={handleNext}
          disabled={currentSlide >= keyBenefits.length - slidesToShow} // Disable if at the end
        />
      </div>
    </Col>
  );
};

const OptionalAddOns = () => {
  const addOns = [
    {
      title: "Competency Assessment",
      description:
        "The tool can define and automate the inclusion of technical and behavioural competencies, ensuring that the PD aligns with both skill requirements and organisational culture.",
      src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228639/girls-having-fun-sharing-book_h0gq2n.jpg",
    },
    {
      title: " Question Suggestions",
      description:
        "This feature offers suggested recruitment questions tailored to the position description, ensuring comprehensive assessment of candidates. These questions are generated using AI and consist of a combination of behavioural and technical questions.",

      src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228912/teen-girls-listening-friend-while-studying_ajoqrn.jpg",
    },
    {
      title: "Position Description Review",
      description:
        "This add-on allows for a collaborative review process of position descriptions, enabling stakeholders to provide input and feedback. This ensures that PDs are clear, comprehensive, and aligned with organizational goals before finalization.",
      src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729229468/three-serious-fellow-students-studying-reading-textbooks_50_xfxesg.webp",
    },
  ];
  return (
    <Col className="section">
      <div className="container px-md-5">
        <div className="section-title text-center">
          <Title level={2}>OPTIONAL ADD ONS</Title>
          <Paragraph>
            Enhance your experience with powerful additional features
          </Paragraph>
        </div>

        <Row
          justify="center"
          gutter={[0, 0]}
          className="card-container text-left"
        >
          {addOns.map((item, index) => (
            <Col xs={24} sm={8} className="card-column">
              <Card bordered={false} className="custom-card">
                <img
                  src={item.src}
                  alt="Synergenies"
                  style={{
                    width: "300px",
                    height: "300px",
                    borderRadius: "50%  50% 50% 0 ", // Make the image round
                  }}
                />
                <Title level={3}>{item.title}</Title>
                <Paragraph className="card-description">
                  {item.description}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Col>
  );
};
